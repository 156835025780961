import { ariaCreateExpander, ariaGetControlledElement } from '@vo/js/utils/aria'
import NavigationBar from '@vo/js/components/navigation-bar/NavigationBar'

const visoNavigationMobile = () => {
    const toggleButtons = document.querySelectorAll('[data-viso-mobile-nav-toggle]')

    if (!toggleButtons) return

    toggleButtons.forEach(toggleButton => {
        ariaCreateExpander(toggleButton, {
            mode: 'attr',
            onToggle: () => {
                document.body.classList.toggle('overflow-hidden')
            }
        })
    })
}

const visoStickyHeader = () => {
    const header = document.querySelector('#site-header')
    const headerSticky = document.querySelector('#viso-site-header-sticky')

    if (!header || !headerSticky) return

    const headerHeight = header.offsetHeight

    toggleStickyHeader(headerHeight)
    window.addEventListener('scroll', () => {
        toggleStickyHeader(headerHeight)
    })

    function toggleStickyHeader(headerHeight) {
        if (window.pageYOffset > headerHeight) {
            headerSticky.removeAttribute('hidden')
        } else {
            headerSticky.setAttribute('hidden', '')
        }
    }
}

const mobileNavToggle = () => {
    const toggle = document.querySelector('#mobile-nav-toggle')

    if (!toggle) return

    ariaCreateExpander(toggle, {
        mode: 'attr',
        onToggle: () => {
            document.body.classList.toggle('overflow-hidden')
            mobileNavHeight()
        }
    })
}

const mobileNavHeight = () => {
    const nav = document.querySelector('#mobile-nav')

    if (!nav) return

    // Set offset top
    nav.style.top = getHeaderHeight() + 'px'

    // Set nav height
    nav.style.height = 'calc(100vh - ' + getHeaderHeight() + 'px)'
}

const mobileNavSubsToggle = () => {
    document.querySelectorAll('[data-module="mobile-nav-subs-toggle"]').forEach((toggle) => {
        toggle.addEventListener('click', () => {
            const controlElements = document.querySelectorAll('[aria-controls="' + toggle.getAttribute('aria-controls') + '"]')
            const controlledElement = ariaGetControlledElement(toggle)
            const controlledElementIsHidden = controlledElement.getAttribute('hidden') === ''

            controlElements.forEach((controlElement) => {
                const isExpanded = controlElement.getAttribute('aria-expanded') === 'true'
                controlElement.setAttribute('aria-expanded', isExpanded ? 'false' : 'true')
            })

            if (controlledElementIsHidden) {
                // Open subs
                controlledElement.removeAttribute('hidden')
                updateMobileNavHeight(controlledElement)
            } else {
                // Close subs
                controlledElement.setAttribute('hidden', '')
                updateMobileNavHeight()
            }
        })
    })
}

function getHeaderHeight () {
    const header = document.querySelector('#site-header')
    const adminBar = document.querySelector('#admin-bar')

    if (!header) return

    let offsetTop = header.offsetHeight

    if (adminBar) {
        offsetTop = offsetTop + adminBar.offsetHeight
    }

    return offsetTop
}

function updateMobileNavHeight (targetNav) {
    const baseHeight = window.innerHeight - getHeaderHeight()
    const nav = document.querySelector('#mobile-nav')
    const navInner = document.querySelector('#mobile-nav-inner')

    if (targetNav) {
        const targetHeight = targetNav.querySelector('ul').scrollHeight
        navInner.style.height = (targetHeight > baseHeight ? targetHeight : baseHeight) + 'px'
        nav.style.overflowY = 'hidden'
    } else {
        navInner.style.height = 'auto'
        nav.style.overflowY = 'auto'
    }
}

export default (function () {
    visoNavigationMobile()
    visoStickyHeader()
    mobileNavToggle()
    mobileNavHeight()
    mobileNavSubsToggle()

    new NavigationBar(document.querySelector('#desktop-nav'))
})
