class Tab {
    constructor(element, parent) {
        this.tab = element
        this.parent = parent

        this.bindClickEvent()
    }

    get panel() {
        return document.getElementById(this.tab.getAttribute('aria-controls'))
    }

    get index() {
        return this.parent.tabs.indexOf(this)
    }

    bindClickEvent() {
        this.tab.addEventListener('click', this.handleClickEvent.bind(this))
    }

    handleClickEvent() {
        this.parent.navigate(this.index)
    }

    open() {
        this.tab.setAttribute('aria-selected', 'true')
        this.tab.setAttribute('tabindex', 0)
        this.tab.focus()
        this.panel.classList.remove('hidden')
        this.panel.setAttribute('tabindex', '0')
    }

    close() {
        this.tab.setAttribute('aria-selected', 'false')
        this.tab.setAttribute('tabindex', -1)
        this.panel.classList.add('hidden')
        this.panel.setAttribute('tabindex', '-1')
    }
}

export default Tab
