import Tab from './Tab'

class TabGroup {
    constructor(element) {
        this.group = element
        this.tabs = []
        this.activeTab = 0

        this.createTabs()
        this.bindKeyboardEvents()
    }

    get tabList() {
        return this.group.querySelector('[role="tablist"]')
    }

    get totalTabs() {
        return this.tabs.length
    }

    get nextTabIndex () {
        return (this.activeTab === this.totalTabs - 1) ? 0 : this.activeTab + 1
    }

    get previousTabIndex () {
        return (this.activeTab === 0) ? this.totalTabs - 1 : this.activeTab - 1
    }

    get lastTabIndex() {
        return this.totalTabs - 1
    }

    createTabs() {
        this.tabList.querySelectorAll('[role="tab"]').forEach((tab) => {
            this.tabs.push(new Tab(tab, this))
        })
    }

    bindKeyboardEvents() {
        this.tabList.addEventListener('keydown', this.handleKeyDownEvent.bind(this))
    }

    handleKeyDownEvent(event) {
        switch (event.key) {
            case 'ArrowRight':
                this.navigate(this.nextTabIndex)
                break
            case 'ArrowLeft':
                this.navigate(this.previousTabIndex)
                break
            case 'Home':
                this.navigate(0)
                break
            case 'End':
                this.navigate(this.lastTabIndex)
        }
    }

    navigate(newIndex) {
        this.tabs.forEach((tab, index) => {
            (index === newIndex)
                ? tab.open()
                : tab.close()
        })
        this.activeTab = newIndex
    }
}

export default TabGroup
