import NavigationBarItem from '@vo/js/components/navigation-bar/NavigationBarItem'

class NavigationBar {
    static instance

    constructor(element) {
        if (!element) return

        if (NavigationBar.instance) {
            return NavigationBar.instance
        } else {
            NavigationBar.instance = this
        }

        this.nav = element

        this.init()
    }

    get list() {
        return this.nav.querySelector('ul')
    }

    get items() {
        return Array.from(this.list.querySelectorAll(':scope > li'))
    }

    get itemsWithSub() {
        return this.items.filter((item) => item.classList.contains('has-children'))
    }

    init() {
        this.createItems()
    }

    createItems() {
        this.itemsWithSub.forEach((item) => new NavigationBarItem(item))
    }
}

export default NavigationBar
