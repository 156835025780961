class NavigationBarItem {
    constructor(element) {
        this.item = element
        this.state = 'closed'

        this.init()
    }

    get titleElement() {
        return this.item.querySelector(':scope > div')
    }

    get buttonElement() {
        return this.titleElement.querySelector('button')
    }

    get subNav() {
        return this.item.querySelector('nav')
    }

    get isOpened() {
        return this.state === 'opened'
    }

    get isClosed() {
        return this.state === 'closed'
    }

    open() {
        this.state = 'opened'
        this.item.classList.add('is-expanded')
        this.titleElement.setAttribute('aria-expanded', 'true')
        this.buttonElement.setAttribute('aria-expanded', 'true')
    }

    close() {
        this.state = 'closed'
        this.item.classList.remove('is-expanded')
        this.titleElement.setAttribute('aria-expanded', 'false')
        this.buttonElement.setAttribute('aria-expanded', 'false')
    }

    init() {
        this.bindClickEvents()
        this.bindFocusEvents()
        this.bindKeyboardEvents()
        this.bindMouseEvents()
    }

    bindClickEvents() {
        this.buttonElement.addEventListener('click', () => this.isOpened ? this.close() : this.open())
    }

    bindFocusEvents() {
        this.subNav.addEventListener('focusout', this.close.bind(this))
    }

    bindKeyboardEvents() {
        document.addEventListener('keydown' , (event) => (this.isOpened && event.key === 'Escape') && this.close())
    }

    bindMouseEvents() {
        this.item.addEventListener('mouseenter', this.open.bind(this))
        this.item.addEventListener('mouseleave', this.close.bind(this))
    }
}

export default NavigationBarItem
