import { ariaIs, ariaGetControlledElement, ariaSet } from '@vo/js/utils/aria'

export default (function () {
    const accordions = document.querySelectorAll('[data-accordion]')

    if (!accordions.length) return
    const open = (toggle) => {
        const target = ariaGetControlledElement(toggle)
        ariaSet('expanded', toggle, 'true')
        target.style.height = `${target.scrollHeight}px`
    }

    const close = (toggle) => {
        const target = ariaGetControlledElement(toggle)
        ariaSet('expanded', toggle, 'false')
        target.style.height = '0px'
    }

    accordions.forEach((accordion) => {
        const buttons = accordion.querySelectorAll('[data-accordion-button]')

        buttons.forEach(button => {
            if (button?.ariaExpanded === 'true') open(button)

            button.addEventListener('click', () => {
                const isOpen = ariaIs('expanded', button)
                const group = button.dataset.accordionGroup ?? false
                const groupedAccordions = group ? document.querySelectorAll(`[data-accordion-group="${group}"]`) : []

                if (groupedAccordions.length) groupedAccordions.forEach((groupedAccordion) => close(groupedAccordion))
                isOpen ? close(button) : open(button)
            })
        })
    })
})
